<template>
  <div class="page">
    <Breadcrumbs/>
    <div class="container">
      <div class="column spacer">
        <h1 class="blog-title">Wat is DNSSEC (DNS Security Extensions)?</h1>
        <h2>Wat is DNSSEC?</h2>
        <p>
          <img
            src="../../assets/images/webhosting.png"
            height="179"
            width="300"
            title="dnssec"
            alt="dnssec"
          >
        </p>
        <p>DNSSEC is een aanvulling op het oude DNS-protocol en is een afkorting voor ‘<a href="https://en.wikipedia.org/wiki/Domain_Name_System_Security_Extensions">DNS Security Extensions</a>‘. Deze aanvulling op het DNS-protocol maakt het gebruik van domeinnamen veiliger. Met DNSSEC is het niet langer mogelijk om via zogeheten <a href="https://en.wikipedia.org/wiki/DNS_spoofing">spoofing</a> of ‘<a href="https://en.wikipedia.org/wiki/Man-in-the-middle_attack">man-in-the-middle</a>‘-aanvallen verkeer naar een domeinnaam te beïnvloeden.</p>
        <p>Om dit soort aanvallen te voorkomen, koppelt DNSSEC het antwoord op een DNS-vraag aan een digitale handtekening. Hierdoor is te controleren of de records die een DNS-server stuurt wel de juiste zijn. Om dit voor elkaar te krijgen, worden DNS-servers voorzien van een systeem voor asymmetrische cryptografie, oftewel ‘<a href="https://en.wikipedia.org/wiki/Public-key_cryptography">public-key cryptography</a>’. DNS-informatie wordt hierdoor versleuteld (getekend) met een private key (privé sleutel). Hierdoor kunnen gebruikers met een public key (publieke sleutel) nagaan of de verstuurde informatie klopt en er dus intussen niet (geautomatiseerd) mee gerommeld is.</p>
        <h2>DNSSEC bij Hosting on Demand</h2>
        <p>Al onze .nl-domeinnamen worden standaard versleuteld met behulp van de DNSSEC-technieken. Wij hebben hiervoor systemen en software ontwikkeld die dit proces volledig automatiseren. Wij vinden het belangrijk dat wij gebruikmaken van alle mogelijkheden die geboden worden om de veiligheid van Internetsystemen en domeinnamen te borgen. Dit vinden wij belangrijk voor onze eigen infrastructuur, voor de ontwikkeling van het Internet en uiteraard ter bescherming van onze klanten.</p>
        <p>Daarom zijn al onze .nl-domeinnamen voorzien van deze belangrijke DNSSEC-bescherming!</p>
        <p><router-link to="/domeinnamen/alle-domeinnaam-extensies/nl-domeinnaam-registreren/">Bestel hier jouw .nl-domeinnaam</router-link></p>
        <h2>DNSSEC overzicht</h2>
        <p>Vanaf 15 mei 2012 kunnen alle .nl-domeinnamen worden voorzien van DNSSEC-beveiliging. Deze door cryptografie ondersteunde beveiliging voegt een extra beschermingslaag toe aan het bestaande DNS-protocol. DNSSEC wordt door Hosting on Demand standaard ondersteund en ingeregeld.</p>
        <h2>DNS-protocol in vogelvlucht</h2>
        <p>DNS, kort voor Domain Name System, verzorgt de vertaling van domeinnamen naar IP-adressen (en vice versa). Een apparaat, zoals bijvoorbeeld een PC of mobiele telefoon, zal een name-server moeten raadplegen om erachter te komen dat een web-server op het IP-adres 91.216.34.100 de verzoeken voor hostingondemand.nl afhandelt. Ook e-mail en andere internet-protocollen maken gebruik van deze mechanismen. Tegenwoordig is het zelfs mogelijk om bepaalde anti-spam directies op te geven in de vorm van DNS-records.</p>
        <h2>Bescherming DNS-communicatie</h2>
        <p>DNSSEC is een cryptografische beveiliging voor het DNS-protocol. Apparaten en systemen die deze uitbreiding ondersteunen, ontvangen van de name-server adresgegevens voorzien van een digitale handtekening. Zo worden de integriteit en authenticiteit van de name-server, maar ook het transport van de DNS-gegevens beschermd.</p>
        <p>Het protocol is voorwaarts ondersteund (forward compatible). Dat wil zeggen dat nieuwere DNS clients en servers voorzien van DNSSEC-ondersteuning gewoon kunnen blijven samenwerken met bestaande systemen die deze uitbreiding nog niet hebben. In die situaties is de DNS-informatie niet ondertekend.</p>
        <h2>Het DNSSEC-protocol</h2>
        <p>Bij gebruik van DNSSEC ontvangt een verzoeker van de name-server adresgegevens die zijn voorzien van een digitale handtekening. De authenticiteit van de opgeleverde records kan geverifieerd worden met behulp van de publieke sleutel voor het domein. Deze sleutel wordt door de houder (uiteindelijk door registrars zoals bijvoorbeeld Hosting on Demand) één niveau hoger bij de registry in de DNS-hiërarchie geplaatst. Voor een .nl-domeinnaam staat de publieke sleutel dus op de name-servers van SIDN, de beheerder van het .nl top-level domein. De sleutel wordt voor clients beschikbaar gemaakt in de vorm van een hash code (een digitaal uittreksel). Hiermee kan een client verifiëren dat de publieke sleutel die hij bij de adres-informatie van de name-server ontvangt inderdaad dezelfde is als die door de houder of beheerder van de domeinnaam bij het trust anchor is aangemeld. Zo wordt een ‘keten van vertrouwen’ (chain of trust) over de verschillende niveau’s van de DNS-hiërarchie opgebouwd.</p>
        <h2>De ondersteuning</h2>
        <p>Alle centrale infrastructuur en faciliteiten voor de ondersteuning van DNSSEC op de .nl-domeinnamen zijn volledig operationeel. Deze uitbreiding wordt nog niet door iedere SIDN (.nl) registrar aangeboden en ondersteund, maar Hosting on Demand voorziet hier inmiddels standaard in.</p>
        <h2>De winst van DNSSEC</h2>
        <p>Het Internet wordt steeds meer lastiggevallen door kwaadwillenden die de beveiligers een stapje voor proberen te blijven. De belangen worden bovendien steeds groter, omdat het Internet steeds meer belangrijke data en functionaliteiten van mensen omvat. Tegenwoordig doen we allemaal onze bank- en overheidszaken online, kopen we spullen online, enzovoorts. Bijna al die communicatie verloopt ook via DNS-systemen en bepaalde aanvallen gericht op specifiek die systemen vallen veel gebruikers van het Internet niet meteen op. De bescherming in de vorm van DNSSEC van zowel dit soort aanvallen als de DNS-communicatie (transport van DNS-gegevens) maakt het Internet veiliger voor iedereen.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from '@/components/Breadcrumbs.vue';

export default {
  name: 'WhatIsDnssecDnsSecurityExtensions',
  components: {
    Breadcrumbs,
  },
}
</script>